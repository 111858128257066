import PageTemplate from './pageTemplate';
import { operationsData } from '../data/operationsData';
import MetaTags from '../components/metaTags';

const Operations = () => {
    return ( 
    <div>
        <MetaTags
                title="Operations Services - 1407"
                description="Optimize your operations with 1407's expertise in technical and operational excellence, performance optimization, and crisis management, notably in the port and maritime sectors."
                keywords="operations services, technical expertise, operational excellence, performance optimization, crisis management, port, maritime, Mille407, 1407"
                url="https://www.mille407.com/operations.html"
            />  
        <PageTemplate {...operationsData} />;
    </div>
    )
};

export default Operations;