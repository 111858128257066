import PageTemplate from './pageTemplate';
import { projectsData } from '../data/projectsData';
import MetaTags from '../components/metaTags';

const Projects = () => {
    return (
     <div>
        <MetaTags
            title="Projects Services - 1407"
            description="Discover 1407's project management services, providing expert guidance in project planning, IT systems management, and project execution, notably in the port and maritime sectors."
            keywords="project management, IT systems management, project planning, project execution, project services, port, maritime, Mille407, 1407"
            url="https://www.mille407.com/projects.html"
        />
        <PageTemplate {...projectsData} />;
    </div>
    )
};

export default Projects;