import techOp1 from '../assets/images/technical-operational-expertise-1.webp';
import techOp2 from '../assets/images/technical-operational-expertise-2.webp';
import techOp3 from '../assets/images/technical-operational-expertise-3.webp';
import techOp4 from '../assets/images/technical-operational-expertise-4.webp';
import techOp5 from '../assets/images/technical-operational-expertise-5.webp';
import perfOpt1 from '../assets/images/performance-optimisation-1.webp';
import perfOpt2 from '../assets/images/performance-optimisation-2.webp';
import perfOpt3 from '../assets/images/performance-optimisation-3.webp';
import perfOpt4 from '../assets/images/performance-optimisation-4.webp';
import perfOpt5 from '../assets/images/performance-optimisation-5.webp';
import procSup1 from '../assets/images/procurment-supply-chain-1.webp';
import procSup2 from '../assets/images/procurment-supply-chain-2.webp';
import procSup3 from '../assets/images/procurment-supply-chain-3.webp';
import procSup4 from '../assets/images/procurment-supply-chain-4.webp';
import procSup5 from '../assets/images/procurment-supply-chain-5.webp';
import crisMan1 from '../assets/images/crisis-management-images-0.webp';
import crisMan2 from '../assets/images/crisis-management-images-1.webp';
import crisMan3 from '../assets/images/crisis-management-images-2.webp';
import crisMan4 from '../assets/images/crisis-management-images-3.webp';
import crisMan5 from '../assets/images/crisis-management-images-4.webp';
import training1 from '../assets/images/training-1.webp';
import training2 from '../assets/images/training-2.webp';
import training3 from '../assets/images/training-3.webp';
import training4 from '../assets/images/training-4.webp';
import training5 from '../assets/images/training-5.webp';
import digital1 from '../assets/images/digital_transformation-1.webp';
import digital2 from '../assets/images/digital_transformation-2.webp';
import digital3 from '../assets/images/digital_transformation-3.webp';
import digital4 from '../assets/images/digital_transformation-4.webp';
import digital5 from '../assets/images/digital_transformation-5.webp';


export const operationsData = {
    title: 'Operations',
    subtitle: 'Discover our services',
    sections: [
        {
            heading: 'Technical & Operational Expertise',
            images: [techOp1, techOp2, techOp3, techOp4, techOp5],
        },
        {
            heading: 'Performance Optimisation',
            images: [perfOpt1, perfOpt2, perfOpt3, perfOpt4, perfOpt5],
        },
        {
            heading: 'Procurement & Supply Chain',
            images: [procSup1, procSup2, procSup3, procSup4, procSup5],
        },
        {
            heading: 'Crisis Management',
            images: [crisMan1, crisMan2, crisMan3, crisMan4, crisMan5],
        },
        {
            heading: 'Training',
            images: [training1, training2, training3, training4, training5],
        },
        {
            heading: 'Digital Transformation',
            images: [digital1, digital2, digital3, digital4, digital5],
        },
        // Add more sections as needed
    ],
};