import Navbar from "./navbar.js";
import Logo from "../assets/images/logo_1407_only_white.png";

const Header = () => {
    return (
        <header className="sticky top-0 z-50">
            <div className="relative h-20 flex items-center justify-between px-4 md:px-8 bg-customBlue bg-opacity-95 backdrop-filter backdrop-blur-lg">
                <div className="flex items-center">
                    <img src={Logo} alt="Logo 1407" className="h-12" />
                    <h1 className="hidden">1407</h1>
                </div>
                <Navbar />
            </div>
        </header>
    );
};

export default Header;