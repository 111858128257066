import MetaTags from "../components/metaTags";
import AirportServices from "../components/airportServices";

const Airport = () => {
    return ( 
    <div>
        <MetaTags
                title="Airport Services - 1407 x Modalis"
                description="1407 services, in the airport sector, through their partnership with Modalis."
                keywords="services, technical expertise, operational excellence, performance optimization, ORAT, training, airport, Mille407, 1407, Modalis"
                url="https://www.mille407.com/airport.html"
        />
        <div className="page-template px-4px-4 py-8 max-w-7xl mx-auto">
    <h2 className="text-3xl md:text-4xl text-center font-bold my-4 text-customBlue">
      Our Airport-Specific Services
    </h2>
    <div className="flex flex-row justify-center items-center pb-4">
      <h3 className="text-2xl text-left text-customBlue">
        with <a href="https://modalis.ca/" rel="noreferrer noopener" target="_blank" className="text-modalisBlue">Modalis</a>
      </h3>
      <svg viewBox="0 0 77 37" className="fill-current w-10 md:w-12 ml-1 text-modalisBlue" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.3132 36.5C25.3132 36.5 56.3598 25.151 34.4801 12.3371C34.4801 12.3371 21.1587 4.64862 0.62207 0.5C0.62207 0.5 48.6183 14.9818 25.3132 36.5Z"></path>
        <path d="M42.8333 34.5073C42.8333 34.5073 68.0125 25.3128 49.9217 14.7374C49.9217 14.7374 39.9336 9.39466 23.6015 5.70659C23.6015 5.70659 61.7394 17.9917 42.8333 34.5073Z"></path>
        <path d="M56.4627 31.5786C56.4627 31.5786 75.6721 24.6626 62.7976 16.1206C62.7976 16.1206 56.8479 11.8898 40.6323 8.55398C40.6323 8.55398 71.4753 17.9103 56.4627 31.5786Z"></path>
        <path d="M69.1538 28.4064C69.1538 28.4064 81.9475 24.0894 73.065 18.3554C73.065 18.3554 70.4572 16.2835 59.5385 13.5984C59.5385 13.5984 78.7684 20.0263 69.1538 28.4064Z"></path>
      </svg>
    </div>
    <p className="text-center text-lg max-w-3xl mx-4 md:mx-auto my-8">
      We offer a diverse range of services tailored to the unique needs of the airport industry. With a focus on enhancing operational efficiency, strategic planning, and customer experience, our services are designed to support airports worldwide in achieving their goals.
    </p>
            <AirportServices />
        </div>  
    </div>
    )
};

export default Airport;