import projManAssist1 from '../assets/images/project-management-assistance-1.webp';
import projManAssist2 from '../assets/images/project-management-assistance-2.webp';
import projManAssist3 from '../assets/images/project-management-assistance-3.webp';
import projManAssist4 from '../assets/images/project-management-assistance-4.webp';
import projManAssist5 from '../assets/images/project-management-assistance-5.webp';
import itSysProjMan1 from '../assets/images/it-systems-project-management-1.webp';
import itSysProjMan2 from '../assets/images/it-systems-project-management-2.webp';
import itSysProjMan3 from '../assets/images/it-systems-project-management-3.webp';
import itSysProjMan4 from '../assets/images/it-systems-project-management-4.webp';
import itSysProjMan5 from '../assets/images/it-systems-project-management-5.webp';
import transitionMan1 from '../assets/images/transition_management-1.webp';
import transitionMan2 from '../assets/images/transition_management-2.webp';
import transitionMan3 from '../assets/images/transition_management-3.webp';
import transitionMan4 from '../assets/images/transition_management-4.webp';
import transitionMan5 from '../assets/images/transition_management-5.webp';
import changeMan1 from '../assets/images/change_management-1.webp';
import changeMan2 from '../assets/images/change_management-2.webp';
import changeMan3 from '../assets/images/change_management-3.webp';
import changeMan4 from '../assets/images/change_management-4.webp';
import changeMan5 from '../assets/images/change_management-5.webp';
import energyTrans1 from '../assets/images/energy_transition-1.webp';
import energyTrans2 from '../assets/images/energy_transition-2.webp';
import energyTrans3 from '../assets/images/energy_transition-3.webp';
import energyTrans4 from '../assets/images/energy_transition-4.webp';
import energyTrans5 from '../assets/images/energy_transition-5.webp';
import newEnergy1 from '../assets/images/new_energies-1.webp';
import newEnergy2 from '../assets/images/new_energies-2.webp';
import newEnergy3 from '../assets/images/new_energies-3.webp';
import newEnergy4 from '../assets/images/new_energies-4.webp';
import newEnergy5 from '../assets/images/new_energies-5.webp';
import gis1 from '../assets/images/cartography_and_gis-1.webp';
import gis2 from '../assets/images/cartography_and_gis-2.webp';
import gis3 from '../assets/images/cartography_and_gis-3.webp';
import gis4 from '../assets/images/cartography_and_gis-4.webp';
import gis5 from '../assets/images/cartography_and_gis-5.webp';
import humanCapital1 from '../assets/images/human_capital-1.webp';
import humanCapital2 from '../assets/images/human_capital-2.webp';
import humanCapital3 from '../assets/images/human_capital-3.webp';
import humanCapital4 from '../assets/images/human_capital-4.webp';
import humanCapital5 from '../assets/images/human_capital-5.webp';




export const projectsData = {
    title: 'Projects',
    subtitle: 'Discover our services',
    sections: [
        {
            heading: 'Project Management & Assistance',
            images: [projManAssist1, projManAssist2, projManAssist3, projManAssist4, projManAssist5],
        },
        {
            heading: 'IT & Systems Project Management',
            images: [itSysProjMan1, itSysProjMan2, itSysProjMan3, itSysProjMan4, itSysProjMan5],
        },
        {
            heading: 'Transition Management',
            images: [transitionMan1, transitionMan2, transitionMan3, transitionMan4, transitionMan5],
        },
        {
            heading: 'Change Management',
            images: [changeMan1, changeMan2, changeMan3, changeMan4, changeMan5],
        },
        {
            heading: 'Energy Transition',
            images: [energyTrans1, energyTrans2, energyTrans3, energyTrans4, energyTrans5],
        },
        {
            heading: 'New Energies',
            images: [newEnergy1, newEnergy2, newEnergy3, newEnergy4, newEnergy5],
        },
        {
            heading: 'Cartography and GIS',
            images: [gis1, gis2, gis3, gis4, gis5],
        },
        {
            heading: 'Human Capital',
            images: [humanCapital1, humanCapital2, humanCapital3, humanCapital4, humanCapital5],
        },
        // Add more sections as needed
    ],
};