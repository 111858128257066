import dueDiligence1 from '../assets/images/due-diligence-EN-1.webp';
import dueDiligence2 from '../assets/images/due-diligence-EN-2.webp';
import dueDiligence3 from '../assets/images/due-diligence-EN-3.webp';
import dueDiligence4 from '../assets/images/due-diligence-EN-4.webp';
import dueDiligence5 from '../assets/images/due-diligence-EN-5.webp';
import concessionOps1 from '../assets/images/concession-operations-images-0.webp';
import concessionOps2 from '../assets/images/concession-operations-images-1.webp';
import concessionOps3 from '../assets/images/concession-operations-images-2.webp';
import concessionOps4 from '../assets/images/concession-operations-images-3.webp';
import concessionOps5 from '../assets/images/concession-operations-images-4.webp';
import salesAcquisition1 from '../assets/images/sales-acquisition-1.webp';
import salesAcquisition2 from '../assets/images/sales-acquisition-2.webp';
import salesAcquisition3 from '../assets/images/sales-acquisition-3.webp';
import salesAcquisition4 from '../assets/images/sales-acquisition-4.webp';
import salesAcquisition5 from '../assets/images/sales-acquisition-5.webp';
import equityInvestment1 from '../assets/images/equity-investement-1.webp';
import equityInvestment2 from '../assets/images/equity-investement-2.webp';
import equityInvestment3 from '../assets/images/equity-investement-3.webp';
import equityInvestment4 from '../assets/images/equity-investement-4.webp';
import equityInvestment5 from '../assets/images/equity-investement-5.webp';
import ventureCapital1 from '../assets/images/venture_capital_and_grants-1.webp';
import ventureCapital2 from '../assets/images/venture_capital_and_grants-2.webp';
import ventureCapital3 from '../assets/images/venture_capital_and_grants-3.webp';
import ventureCapital4 from '../assets/images/venture_capital_and_grants-4.webp';
import ventureCapital5 from '../assets/images/venture_capital_and_grants-5.webp';

export const transactionsData = {
    title: 'Transactions',
    subtitle: 'Discover our services',
    sections: [
        {
            heading: 'Due Diligence',
            images: [dueDiligence1, dueDiligence2, dueDiligence3, dueDiligence4, dueDiligence5],
        },
        {
            heading: 'Concession Operations',
            images: [concessionOps1, concessionOps2, concessionOps3, concessionOps4, concessionOps5],
        },
        {
            heading: 'Sales Acquisition',
            images: [salesAcquisition1, salesAcquisition2, salesAcquisition3, salesAcquisition4, salesAcquisition5],
        },
        {
            heading: 'Equity Investment',
            images: [equityInvestment1, equityInvestment2, equityInvestment3, equityInvestment4, equityInvestment5],
        },
        {
            heading: 'Venture Capital and Grants',
            images: [ventureCapital1, ventureCapital2, ventureCapital3, ventureCapital4, ventureCapital5],
        }
    ],
};