import { Link } from "react-router-dom";
import Chess from "../assets/images/chess1.webp";
import Container from "../assets/images/container4.webp";
import Ship from "../assets/images/ship0.webp";
import Port from "../assets/images/port7.webp";

const Stop = () => {
    return (
        <div className="container mx-auto px-4 py-8 text-customBlue animate-fadeInUp">
            <h2 className="text-3xl font-bold mb-8 text-center">
                1407 <span className="text-customRed">•</span> Shaping the Future for the Port and Maritime Industry
            </h2>
            <div className="flex flex-col sm:flex-row flex-wrap justify-center mb-4 w-full mx-auto">
                {/* Image and Word Containers */}
                <Link to="/strategy.html" className="relative w-full sm:w-1/4 p-2 text-center transform transition-transform duration-300 hover:scale-105">
                    <div className="flex flex-col items-center">
                        <img src={Chess} alt="Strategy" className="w-auto h-40 md:h-60" />
                        <span className="bg-white bg-opacity-90 px-2 py-1 text-2xl uppercase text-customBlue font-bold mt-2">Strategy</span>
                    </div>
                </Link>
                <Link to="/transactions.html" className="relative w-full sm:w-1/4 p-2 text-center transform transition-transform duration-300 hover:scale-105">
                    <div className="flex flex-col items-center">
                        <img src={Container} alt="Transactions" className="w-auto h-40 md:h-60" />
                        <span className="bg-white bg-opacity-90 px-2 py-1 text-2xl uppercase text-customBlue font-bold mt-2">Transactions</span>
                    </div>
                </Link>
                <Link to="/operations.html" className="relative w-full sm:w-1/4 p-2 text-center transform transition-transform duration-300 hover:scale-105">
                    <div className="flex flex-col items-center">
                        <img src={Ship} alt="Operations" className="w-auto h-40 md:h-60 rounded-full" />
                        <span className="bg-white bg-opacity-90 px-2 py-1 text-2xl uppercase text-customBlue font-bold mt-2">Operations</span>
                    </div>
                </Link>
                <Link to="/projects.html" className="relative w-full sm:w-1/4 p-2 text-center transform transition-transform duration-300 hover:scale-105">
                    <div className="flex flex-col items-center">
                        <img src={Port} alt="Projects" className="w-auto h-40 md:h-60" />
                        <span className="bg-white bg-opacity-90 px-2 py-1 text-2xl uppercase text-customBlue font-bold mt-2">Projects</span>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Stop;