import Carousel from '../components/carousel';

const PageTemplate = ({ title, subtitle, sections }) => {
    return (
        <div className="page-template px-4">
            <h2 className="text-3xl text-center font-bold my-4 text-customBlue">{title}</h2>
            <h3 className="text-2xl text-center pb-4">{subtitle}</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 gap-x-12 sm:w-2/3 md:w-1/2 lg:w-2/3 2xl:w-1/2 mx-auto py-10">
                {sections.map((section, index) => (
                    <div key={index}>
                        <h4 className="text-customDarkGray font-bold text-lg">{section.heading}</h4>
                        <Carousel images={section.images} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PageTemplate;