import stratCons1 from '../assets/images/strategic-consulting-1.webp';
import stratCons2 from '../assets/images/strategic-consulting-2.webp';
import stratCons3 from '../assets/images/strategic-consulting-3.webp';
import stratCons4 from '../assets/images/strategic-consulting-4.webp';
import stratCons5 from '../assets/images/strategic-consulting-5.webp';
import orgCons1 from '../assets/images/organisation-consulting-1.webp';
import orgCons2 from '../assets/images/organisation-consulting-2.webp';
import orgCons3 from '../assets/images/organisation-consulting-3.webp';
import orgCons4 from '../assets/images/organisation-consulting-4.webp';
import orgCons5 from '../assets/images/organisation-consulting-5.webp';

export const strategyData = {
    title: 'Strategy',
    subtitle: 'Discover our services',
    sections: [
        {
            heading: 'Strategic Consulting',
            images: [stratCons1, stratCons2, stratCons3, stratCons4, stratCons5]
        },
        {
            heading: 'Organisation Consulting',
            images: [orgCons1, orgCons2, orgCons3, orgCons4, orgCons5]
        }
    ]
};