import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Preload the first image
    const img = new Image();
    img.src = images[0];
  }, [images]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="preload" href={images[0]} as="image" />
      </Helmet>
      <div className="relative w-full mx-auto my-4">
        <div className="relative overflow-hidden pb-[125%]">
          {images.map((image, index) => (
            <LazyLoad key={index} height={'100%'} offset={100} once>
              <div
                className={`absolute inset-0 transition-opacity duration-500 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-contain"
                  loading={index === 0 ? 'eager' : 'lazy'}
                />
              </div>
            </LazyLoad>
          ))}
        </div>
        <div className="flex justify-center mt-4 mb-10">
          <button
            title="previous_btn"
            type="button"
            className="bg-customBlue bg-opacity-60 text-gray-50 p-2 mx-2 rounded-full"
            onClick={prevSlide}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            title="next_btn"
            type="button"
            className="bg-customBlue bg-opacity-60 text-gray-50 p-2 mx-2 rounded-full"
            onClick={nextSlide}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Carousel;