import Stop from "../components/stop";
import Survey from "../components/survey";
import Team from "../components/team" ;
import MetaTags from '../components/metaTags';


const Home = () => {
    return (
        <div>
            <MetaTags
                title="Home Page - 1407"
                description="Welcome to 1407, your trusted partner in strategic consulting, operational expertise, and project management, notably in the port and maritime sectors. Discover how our dedicated team helps businesses achieve excellence."
                keywords="Mille407, 1407, strategic consulting, operational expertise, project management, business consulting, business excellence, port, maritime"
                url="https://www.mille407.com/"
            />
            <main className="w-full">
                <Stop />
                <Team />
                <Survey />
            </main>
        </div>
    ); 
};

export default Home;