const LegalNotice = () => {
    return (
    <div className="container mx-auto px-4 py-8 text-customBlue">
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Site Publishing</h2>
                <p className="text-sm">
                    The Site is published by MILLE407 SAS, a company with a capital of €30,000.00, registered with the La Rochelle Trade and Companies Register under the SIRET number 83219420300010 (NAF code 7022Z), with its registered office located at 76 B avenue Raymond Poincaré 17000 La Rochelle, France (hereinafter "MILLE407").
                </p>
                <p className="text-sm mt-2">
                    Phone number: +33 (0) 6 33 65 37 25<br/>
                    Email address: contact@<b>hellothere.</b>mille407.com<br/>
                    VAT number: FR16832194203
                </p>
                <p className="text-sm mt-2">
                    The Publishing Director is Mr. Matthieu DESIDERIO.
                </p>
                <p className="text-sm mt-2">
                    Photo credits: Pixabay; MILLE407 SAS; Maxime Pagnoux; DALL E.
                </p>
                <p className="text-sm mt-2">
                    The Site is hosted by O2Switch, whose headquarters is located at 222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France.
                </p>
            </section>
            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Acceptance of the Terms and Conditions of Use</h2>
                <p className="text-sm">
                    These Terms and Conditions of Use (TCU) govern the use of the Site. They apply to you as soon as you access the Site, whether from the homepage or any other page.
                </p>
                <p className="text-sm mt-2">
                    Connecting to and browsing the Site by the User implies full and unconditional acceptance of these TCU. Users who do not wish to be bound by these TCU must immediately leave the Site and cease its use.
                </p>
                <p className="text-sm mt-2">
                    MILLE407 reserves the right to modify and update the TCU at any time. Each new version of the Terms and Conditions of Use comes into effect from the date of its online publication on the Site.
                </p>
                <p className="text-sm mt-2">
                    You are therefore invited to regularly review the terms and conditions of use in effect at the time of your access to the Site, from the homepage or any other page.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Site Access</h2>
                <p className="text-sm">
                    The Site is freely accessible from any location to any User with Internet access. All costs incurred by the User to access the service (computer hardware, software, Internet connection, etc.) are at their own expense.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Data Collection and Management</h2>
                <p className="text-sm">
                    The Site ensures that the collection and processing of personal information respects privacy in accordance with the French law n°78-17 of January 6, 1978, on information technology, data files, and civil liberties, and the provisions of the General Data Protection Regulation (GDPR: n° 2016-679) (hereinafter "GDPR").
                </p>
                <p className="text-sm mt-2">
                    Under the Data Protection Act of January 6, 1978, and the provisions of the GDPR, the User has the right to access, rectify, delete, and oppose their personal data. The User can exercise this right by:
                </p>
                <ul className="list-disc list-inside text-sm mt-2">
                    <li>Email to contact@<b>hellothere.</b>mille407.com;</li>
                    <li>Postal mail to 76 B avenue Raymond Poincaré 17000 La Rochelle, France;</li>
                    <li>Using a contact form.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Intellectual Property</h2>
                <p className="text-sm">
                    The Site and its content are protected by the Intellectual Property Code, particularly by copyright, which are the property of MILLE407 or third parties.
                </p>
                <p className="text-sm mt-2">
                    The User must seek prior permission from MILLE407 for any reproduction, publication, or copying of the different contents. The User agrees to use the contents of the Site strictly for private purposes; any commercial or advertising use is strictly prohibited.
                </p>
                <p className="text-sm mt-2">
                    Any total or partial representation of the Site by any means whatsoever, without the express authorization of MILLE407, would constitute an infringement punishable by articles L 335-2 and following of the Intellectual Property Code.
                </p>
                <p className="text-sm mt-2">
                    It is reminded, in accordance with article L122-5 of the Intellectual Property Code, that the User who reproduces, copies, or publishes protected content must cite the author and source.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Liability</h2>
                <p className="text-sm">
                    The information sources disseminated on the Site are deemed reliable, but MILLE407 does not guarantee that they are free from defects, errors, or omissions.
                </p>
                <p className="text-sm mt-2">
                    The information provided is presented for indicative and general purposes and has no contractual value. Despite regular updates, MILLE407 cannot be held responsible for the modification of administrative and legal provisions occurring after publication. Similarly, MILLE407 cannot be held responsible for the use and interpretation of the information contained in the Site.
                </p>
                <p className="text-sm mt-2">
                    MILLE407 cannot be held responsible for any viruses that may infect the User"s computer or any hardware following use, access, or downloading from the Site.
                </p>
                <p className="text-sm mt-2">
                    MILLE407"s liability cannot be engaged in case of force majeure or the unforeseeable and insurmountable act of a third party.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Hyperlinks</h2>
                <p className="text-sm">
                    The Site may contain links to third-party websites. These links are provided for convenience only, and the inclusion of these links does not imply control of the elements of the sites to which the links provide access, nor any endorsement or warranty of these elements. Access to these sites is the sole responsibility of the User.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4" id="cookies-policy">Cookies</h2>
                <p className="text-sm">
                    The User is informed that during their visits to the Site, a cookie may be automatically installed on their browser software. Cookies are small files stored temporarily on the User's computer hard drive by the browser and are necessary for the use of the Site. Cookies do not contain personal information and cannot be used to identify anyone. A cookie contains a unique, randomly generated identifier and is therefore anonymous. Some cookies expire at the end of the User's visit, others remain. The information contained in cookies is used to improve the Site. By browsing the Site, the User accepts them. The User must, however, give their consent to the use of certain cookies. Failing acceptance, the User is informed that certain features or pages may be refused. The User can disable these cookies through the settings in their browser software.
                </p>
                <p className="text-sm mt-2">
                    <i>Essential Cookies</i>:
                    <br/>
                    Cookie Consent Cookie: cookiesAccepted, cookiesRefused
                    <br/>
                    Purpose: These cookies store the user's preferences regarding cookie consent. When a user accepts or refuses cookies, these cookies remember the user's choice so that they are not prompted again on subsequent visits.
                    <br/>
                    Duration: 365 days.
                    <br/>
                    <i>Analytics Cookies: Google Analytics</i>
                    <br/>
                    Name: _ga, _gid, _gat
                    <br/>
                    Purpose: These cookies are used to collect information about how visitors use the site. This information is used to compile reports and help us improve the site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from, and the pages they visited.
                    <br/>
                    Duration:
                    <br/>
                    _ga: 2 years (The main Google Analytics cookie, used to distinguish users.)
                    <br/>
                    _ga_[container-id]: 2 years (A variant of the _ga cookie, specific to our Google Analytics property, also used to distinguish users.)
                    <br/>
                    _gid: 24 hours
                    <br/>
                    _gat: 1 minute
                    <br/>
                    We use Google Analytics to analyze the use of our website. Google Analytics generates statistical data about the use of the website by means of cookies. (<a href="https://commission.europa.eu/cookies-policy_en" className="text-gray-600">https://commission.europa.eu/cookies-policy_en</a>) 
                </p>
                <p className="text-sm mt-2">
                    We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new policy on our Site with a new effective date. 
                </p>
            
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Modifications</h2>
                <p className="text-sm">
                    MILLE407 may modify the elements published on the Site or any information included therein at any time and without prior notice.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Applicable Law and Jurisdiction</h2>
                <p className="text-sm">
                    The Site and these TCU are subject to French law. In the absence of an amicable resolution of a dispute arising between the parties, only French courts shall have jurisdiction.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Definitions</h2>
                <p className="text-sm">
                    <strong>Client:</strong> Any professional or capable person within the meaning of articles 1123 and following of the Civil Code, or legal entity, visiting the Site subject to these terms and conditions of use.
                </p>
                <p className="text-sm mt-2">
                    <strong>Content:</strong> All the elements constituting the information present on the Site, including texts, images, sounds, and videos.
                </p>
                <p className="text-sm mt-2">
                    <strong>User:</strong> Internet user connecting to, using the Site.
                </p>
                <p className="text-sm mt-2">
                    <strong>Personal information:</strong> "Information that allows, in any form whatsoever, directly or indirectly, the identification of individuals to which it applies" (article 4 of the law n° 78-17 of January 6, 1978).
                </p>
                <p className="text-sm mt-2">
                    The terms "personal data", "data subject", "processor", and "sensitive data" have the meanings defined by the General Data Protection Regulation (GDPR: n° 2016-679).
                </p>
            </section>
        </div>
);

};

export default LegalNotice;