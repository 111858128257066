import PageTemplate from './pageTemplate';
import { strategyData } from '../data/strategyData';
import MetaTags from '../components/metaTags';

const Strategy = () => {
    return ( 
    <div>
        <MetaTags
                title="Strategy Services - 1407"
                description="Explore 1407's strategy services designed to help businesses develop and implement effective strategies for sustainable growth and competitive advantage, notably in the port and maritime sectors."
                keywords="strategy services, business strategy, strategic planning, sustainable growth, competitive advantage, port, maritime, Mille407, 1407"
                url="https://www.mille407.com/strategy.html"
            />
        <PageTemplate {...strategyData} />;
    </div>
    )
};

export default Strategy;