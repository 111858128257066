import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isMobileMenuOpen && !event.target.closest("#mobile-menu") && !event.target.closest("button[title='menu-toggle']")) {
                closeMobileMenu();
            }
        };

        if (isMobileMenuOpen) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [isMobileMenuOpen]);

    return (
        <div className="flex items-center">
            <nav className="hidden lg:flex space-x-6">
                <Link to="/" className="text-lg font-bold text-white hover:text-customRed border-r-2 border-r-customDarkGray pr-4">Home</Link>
                <Link to="/strategy.html" className="text-lg font-bold text-white hover:text-customRed">Strategy</Link>
                <Link to="/transactions.html" className="text-lg font-bold text-white hover:text-customRed">Transactions</Link>
                <Link to="/operations.html" className="text-lg font-bold text-white hover:text-customRed">Operations</Link>
                <Link to="/projects.html" className="text-lg font-bold text-white hover:text-customRed">Projects</Link>
                <Link to="/airport.html" className="text-lg font-bold text-white hover:text-customRed border-l-2 border-l-customDarkGray pl-4">Airport</Link>
            </nav>
            <div className="flex items-center ml-4">
                <button title="menu-toggle" onClick={toggleMobileMenu} className="lg:hidden text-white hover:text-customRed focus:outline-none">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            {isMobileMenuOpen && (
                <div id="mobile-menu" className="lg:hidden absolute top-20 left-0 right-0 bg-customBlue bg-opacity-95 backdrop-filter backdrop-blur-lg p-4">
                    <nav className="flex flex-col space-y-2 md:pl-4">
                        <Link to="/" onClick={closeMobileMenu} className="text-lg font-bold text-white hover:text-customRed border-b-2 border-b-customDarkGray pb-2">Home</Link>
                        <Link to="/strategy.html" onClick={closeMobileMenu} className="text-lg font-bold text-white hover:text-customRed">Strategy</Link>
                        <Link to="/transactions.html" onClick={closeMobileMenu} className="text-lg font-bold text-white hover:text-customRed">Transactions</Link>
                        <Link to="/operations.html" onClick={closeMobileMenu} className="text-lg font-bold text-white hover:text-customRed">Operations</Link>
                        <Link to="/projects.html" onClick={closeMobileMenu} className="text-lg font-bold text-white hover:text-customRed">Projects</Link>
                        <Link to="/airport.html" onClick={closeMobileMenu} className="text-lg font-bold text-white hover:text-customRed border-t-2 border-t-customDarkGray py-2">Airport</Link>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default Navbar;