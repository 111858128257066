const Survey = () => {
    return (
        <div className="bg-white w-full mx-auto px-4 py-8 text-customBlue animate-fadeInUp">
            <h2 className="text-3xl font-bold mb-8 text-center">Your opinion matters to us</h2>
            <div className="flex flex-col md:flex-row justify-center items-center md:items-center lg:w-2/3 mx-auto">
                <div className="md:w-1/2 p-4">
                    <p className="text-lg mb-4">
                        At 1407, we strive to provide great services with exceptional customer experience. To help us with our goal to exceed your expectations, we would appreciate your feedback on our recent project: thank you for taking the 1407 Client Satisfaction Survey.
                    </p>
                </div>
                <div className="md:w-1/2 p-4 flex flex-col items-center justify-center">
                    <a href="https://forms.office.com/pages/responsepage.aspx?id=jf5MNsOmR0esYUIer1m-yiK-MGyfaSlKmQNhHumxWEFUM081T1BXMU05TTZZS09BTDlINjhLSFc5Vy4u" target="_blank" rel="noopener noreferrer" className="bg-customRed text-white font-bold py-4 px-8 rounded-full mb-4 hover:bg-red-600 transform transition-transform duration-300 hover:scale-105">
                        Take the Survey
                    </a>
                    <p className="text-sm text-gray-600">
                        Find more on <a href="https://portsurveys.anchorpoint.world" target="_blank" rel="noopener noreferrer" className="text-customBlue underline">PortSurveys</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Survey;