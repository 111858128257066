import PageTemplate from './pageTemplate';
import { transactionsData } from '../data/transactionsData';
import MetaTags from '../components/metaTags';

const Transactions = () => {
    return (
    <div>
        <MetaTags
                title="Transactions Services - 1407"
                description="Unlock your business potential with 1407's transactions services, including mergers and acquisitions, financial advisory, and due diligence, notably in the port and maritime sectors."
                keywords="transactions services, mergers and acquisitions, financial advisory, due diligence, business transactions, port, maritime, Mille407, 1407"
                url="https://www.mille407.com/transactions.html"
        />
        <PageTemplate {...transactionsData} />;
    </div>
    )
};

export default Transactions;