import { Link } from 'react-router-dom'; 
import RoundLogo from "../assets/images/logo_1407_round_red.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="flex flex-col bg-customBlue text-white py-8">
            <div className="px-4 md:px-10 flex flex-col sm:flex-row justify-center sm:justify-between items-center sm:items-start text-center sm:text-left">
                {/* Middle Column */}
                <div className="mx-5 mb-4 lg:mb-0 flex flex-col items-center sm:order-2">
                    <ul className="list-none p-0 text-center">
                        <li>Contact us</li>
                        <li className="mb-2 font-bold"> 
                            contact@<b>hellothere.</b>mille407.com
                        </li>
                        <li className="mb-2">
                            <a href="https://www.linkedin.com/company/mille407/" target="_blank" rel="noopener noreferrer" className="text-white no-underline hover:text-customRed flex items-center justify-center">
                                <span className="pr-2">LinkedIn</span>
                                <FontAwesomeIcon icon={faLinkedin} size="lg" />
                                <span className="sr-only">LinkedIn - 1407</span>
                            </a>
                        </li>
                    </ul>
                </div>
                
                {/* Right Column */}
                <div className="mx-5 mb-4 lg:mb-0 flex flex-col items-center sm:order-3">
                    <ul className="list-none p-0 text-center">
                        <li className="mb-2">
                            <Link to="/legal-notice.html" className="text-white no-underline hover:text-customRed">
                                Legal Notice
                            </Link>
                        </li>
                        <li className="mb-2">
                            <img src={RoundLogo} alt="Logo 1407" className="w-16 mx-auto"/>
                        </li>
                    </ul>
                </div>

                {/* Left Column */}
                <div className="flex flex-col items-center mx-5 mb-4 lg:mb-0 sm:order-1">
                    <p className="my-2 text-xs">
                        76 B AVENUE RAYMOND POINCARE<br />
                        17000 LA ROCHELLE<br />
                        SIRET 83219420300010<br />
                        RCS LA ROCHELLE – NAF 7022Z<br />
                        VAT number FR16832194203
                    </p>
                </div>
            </div>
            {/* End Footer Links */}
        </footer>
    );
};

export default Footer;
