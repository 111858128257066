import airportImage1 from '../assets/images/card_airport.webp';
import airportImage2 from '../assets/images/card_airport2.webp';
import airportImage3 from '../assets/images/card_airport3.webp';
import buildingImage from '../assets/images/card_building.webp';
import coopImage from '../assets/images/card_coop.webp';
import energyImage from '../assets/images/card_energy.webp';
import helicopterImage from '../assets/images/card_helicopter.webp';
import opsImage from '../assets/images/card_ops.webp';
import seaImage from '../assets/images/card_sea.webp';
import trainingImage from '../assets/images/card_training.webp';

const servicesData = [
  {
    title: 'Mergers & Acquisitions',
    subtitle: 'Deal Sourcing & Negotiation, Bid Management, Due Diligence',
    bulletPoints: [
      'We identify and negotiate deals, ensuring optimal outcomes for all parties involved.',
      'Our team manages the feasibility assessments and bid processes for new projects and concessions.',
      'Comprehensive due diligence to facilitate primary and secondary transactions.'
    ],
    image: coopImage,
  },
  {
    title: 'Business Intelligence',
    subtitle: 'Industry Insights, Market Analysis',
    bulletPoints: [
      'We provide detailed data and analysis on private-sector airport transactions, tracking over 30 operators and 100 deals globally.',
      'In-depth analysis of market trends to support strategic decision-making.'
    ],
    image: airportImage2,
  },
  {
    title: 'Strategic & Business Advisory',
    subtitle: 'Financial Modeling, Route and Traffic Forecasting, Commercial Development Strategy',
    bulletPoints: [
      'Expert financial modeling to support business planning and development.',
      'Accurate forecasting to aid in strategic route development and traffic management.',
      'Strategies to enhance commercial operations and revenue.'
    ],
    image: airportImage3,
  },
  {
    title: 'Master Planning & Development',
    subtitle: 'Comprehensive Planning',
    bulletPoints: [
      'We offer master planning services to guide airport development projects from conception to completion.'
    ],
    image: buildingImage,
  },
  {
    title: 'Operations, Operational Readiness & Training',
    subtitle: 'Operational Performance, Regulatory Compliance, Training & Proficiency',
    bulletPoints: [
      'Enhancing operational efficiency through targeted performance improvement initiatives.',
      'Ensuring compliance with aviation safety and regulatory standards.',
      'Providing training programs to improve operational readiness and proficiency.'
    ],
    image: trainingImage,
  },
  {
    title: 'Quality & Performance Assessment',
    subtitle: 'Quality Management, Performance Metrics',
    bulletPoints: [
      'Implementing best practices in quality management to ensure high standards across all operations.',
      'Regular assessment of performance metrics to drive continuous improvement.'
    ],
    image: opsImage,
  },
  {
    title: 'Customer Experience Excellence',
    subtitle: 'Strategic Alignment, Engagement Strategies',
    bulletPoints: [
      'Aligning customer experience strategies with overall business goals.',
      'Developing strategies for passenger, stakeholder, and employee engagement to enhance satisfaction and loyalty.'
    ],
    image: airportImage1,
  },
  {
    title: 'Human Resource & Talent Solutions',
    subtitle: 'Expert Sourcing, On-Demand Advisors',
    bulletPoints: [
      'Connecting investors and operators with highly qualified experts for various project needs.',
      'Providing on-demand advisory services for short, medium, and long-term engagements.'
    ],
    image: seaImage,
  },
  {
    title: 'Vertiports Planning & Development',
    subtitle: 'Ground Infrastructure, Sustainable Solutions',
    bulletPoints: [
      'Supporting the development of ground infrastructure for electric vertical take-off and landing (eVTOL) aircraft.',
      'Promoting environmentally responsible and sustainable urban transportation solutions.'
    ],
    image: helicopterImage,
  },
  {
    title: 'Key Value Drivers',
    subtitle: 'Full Investment Cycle Support, Industry-Wide Networks, Practical Experience',
    bulletPoints: [
      'We provide support throughout the entire investment lifecycle, from target identification to operational support.',
      'Leveraging extensive networks and relationships within the industry.',
      'Drawing on real-world, hands-on experience to deliver practical solutions.'
    ],
    image: energyImage,
  }
];
const ServiceBlock = ({ title, subtitle, bulletPoints, image }) => (
  <div className="flex flex-col lg:flex-row mb-8 transform transition-transform duration-300 hover:scale-105">
    <div className="relative lg:w-1/4 flex-shrink-0 rounded-md">
      <img src={image} alt={title} className="w-full h-28 rounded-md object-cover" />
      <div className="absolute bottom-0 left-0 w-full h-full flex flex-col justify-end items-start rounded-md text-gray-50 p-4 bg-customBlue bg-opacity-80">
        <h2 className="text-md font-bold">{title}</h2>
        <h3 className="text-sm">{subtitle}</h3>
      </div>
    </div>
    <div className="lg:w-3/4 lg:px-4 flex items-center">
      <ul className="custom-list w-full">
        {bulletPoints.map((point, index) => (
          <li key={index} className="my-2 bg-gray-100 p-2 rounded-r-lg w-full">{point}</li>
        ))}
      </ul>
    </div>
  </div>
);

const AirportServices = () => (
  <div className="container mx-auto p-4">
    {servicesData.map((service, index) => (
      <ServiceBlock
        key={index}
        title={service.title}
        subtitle={service.subtitle}
        bulletPoints={service.bulletPoints}
        image={service.image}
      />
    ))}
  </div>
);

export default AirportServices;